import React from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import useBindingContext from '../../../hooks/useBindingContext';
import { BindingContext, BindingContextType, GameEdition } from '../../../store/features/config-editor/config-editor-slice';
import Aliases from './Aliases';
import VirtualKeyboard from './VirtualKeyboard';

interface Props {
    isCollapsed: boolean;
    onToggleCollapse: () => void;
    edition: GameEdition;
    onEditionChange: (v: GameEdition) => void;
}

const bindingNames: Record<BindingContextType, string> = {
    [BindingContextType.Keyboard]: "Keyboard",
    [BindingContextType.OneTime]: "Settings",
    [BindingContextType.CustomAlias]: "Aliases",
}

const bindingDefaults: Record<BindingContextType, BindingContext> = {
    [BindingContextType.Keyboard]: {
        type: BindingContextType.Keyboard,
        isKeyDown: true,
        keys: []
    },
    [BindingContextType.OneTime]: {
        type: BindingContextType.OneTime
    },
    [BindingContextType.CustomAlias]: {
        type: BindingContextType.CustomAlias
    },
}

const BindingSource = ({ isCollapsed, onToggleCollapse, edition, onEditionChange }: Props) => {

    const [bindingContext, setBindingContext] = useBindingContext();

    const showSequenceTip = bindingContext.type === BindingContextType.Keyboard && bindingContext.keys.length === 1;

    const BindingItem = ({ type }: { type: BindingContextType }) => {
        return (
            <Button
                size='sm' variant={bindingContext.type === type ? 'success' : 'outline-primary'}
                className='m-l-5' onClick={() => setBindingContext(bindingDefaults[type])}
            >
                {bindingNames[type]}
            </Button>
        )
    }

    return (
        <Col xs={12}>
            <Card>
                <Card.Header className='d-flex justify-content-between'>
                    <Card.Text className="text-primary m-b-0 f-20 f-w-600" role='button' onClick={onToggleCollapse}>
                        Binding target
                    </Card.Text>
                    <div>
                        {showSequenceTip && (
                            <small className='m-r-5'>
                                Hold shift to create key sequences
                            </small>
                        )}
                        {/* <Button size='sm' onClick={() => onEditionChange(edition === GameEdition.CSGO ? GameEdition.CS2 : GameEdition.CSGO)}>
                            {GameEdition[edition]} 
                        </Button> */}
                        <BindingItem type={BindingContextType.Keyboard} />
                        <BindingItem type={BindingContextType.OneTime} />
                        <BindingItem type={BindingContextType.CustomAlias} />
                    </div>
                </Card.Header>
                {!isCollapsed && bindingContext.type !== BindingContextType.OneTime &&
                    <Card.Body>
                        {bindingContext.type === BindingContextType.Keyboard && <VirtualKeyboard edition={edition} />}
                        {bindingContext.type === BindingContextType.CustomAlias && <Aliases />}
                    </Card.Body>
                }
            </Card>
        </Col>
    )
}

export default BindingSource;
