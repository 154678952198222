import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import useBindingContext from '../../../hooks/useBindingContext';
import { GameEdition } from '../../../store/features/config-editor/config-editor-slice';
import BindingItems from './BindingItems';
import BindingSource from './BindingSource';
import Library from './Library';

type CardKey = "binding-source" | "bound-items" | "item-tabs"

const ConfigEditor = () => {

    const [edition, setEdition] = useState<GameEdition>(GameEdition.CS2);

    const [bindingContext] = useBindingContext();

    const [collapsedKeys, setCollapsedKeys] = useState<CardKey[]>([]);

    const toggle = (key: CardKey) => {
        if (collapsedKeys.includes(key)) {
            setCollapsedKeys(collapsedKeys.filter(k => k !== key));
        }
        else {
            setCollapsedKeys([...collapsedKeys, key])
        }
    }

    useEffect(() => {
        setCollapsedKeys(collapsedKeys.filter(k => k !== "binding-source" && k !== "bound-items"));
    }, [bindingContext]);

    return (
        <>
            <Row>
                <BindingSource
                    isCollapsed={collapsedKeys.includes("binding-source")}
                    onToggleCollapse={() => toggle("binding-source")}
                    edition={edition}
                    onEditionChange={setEdition}
                />
            </Row>
            <Row>
                <BindingItems isCollapsed={collapsedKeys.includes("bound-items")} onToggleCollapse={() => toggle("bound-items")}/>
            </Row>
            <Row>
                <Library/>
            </Row>
        </>
    );
};
export default ConfigEditor;
